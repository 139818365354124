import React, { useEffect, useState } from "react";
import "./loading.css";

const App = () => {
  const [progress, setProgress] = useState(0); 
  const [showComingSoon, setShowComingSoon] = useState(false); 
  const [showImages, setShowImages] = useState(false); 
  const [currentImage, setCurrentImage] = useState(0); 

  const images = [
    require("../../media/loading/confused_lady.png"), 
    require("../../media/loading/confused_lady(1).png"),
  ];

  useEffect(() => {
    const stopAt = 90;
    let interval;

    const simulateProgress = () => {
      const randomIncrement = Math.random();
      setProgress((prevProgress) => {
        if (prevProgress < stopAt) {
          return prevProgress + randomIncrement;
        } else {
          clearInterval(interval); 
          setTimeout(() => {
            setShowComingSoon(true);
          }, 500); 
          setTimeout(() => {
            setShowImages(true);
          }, 500);
          return prevProgress;
        }
      });
    };

    interval = setInterval(simulateProgress, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let imageInterval;
    if (showImages) {
      imageInterval = setInterval(() => {
        setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
      }, 500);
    }
    return () => clearInterval(imageInterval);
  }, [showImages, images.length]);

  return (
    <div className="loading-container">
      {!showComingSoon ? (
        <div className="count font-roboto tracking-[0.7rem]">{Math.round(progress)}%</div>
      ) : (
        <div className="coming-soon font-light font-roboto tracking-[0.7rem]">COMING SOON</div>
      )}
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>

      {showImages && (
        <div className="loading-image-container">
          <img
            src={images[currentImage]}
            alt={`Switching ${currentImage + 1}`}
            className="switch-image"
          />
        </div>
      )}
    </div>
  );
};

export default App;
