import React from "react";

const Services = () => {
  const videoBackground = require("../media/services/3773486-hd_1920_1080_30fps.mp4");
  const Interior = require("../media/services/Services_Interior.jpg");
  const Furniture = require("../media/services/Service_Furniture.jpg");
  const Decor = require("../media/services/Service_Decor.png");

  // Fallback image
  const fallbackImage = require("../media/services/fallbackImage-min.png");


  return (
    <div className="text-left">
      <div
        className="relative z-10 h-[100vh] bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${fallbackImage})` }}
      >
        
          <video
            className="absolute inset-0 w-full h-full object-cover z-0"
            src={videoBackground}
            autoPlay
            loop
            muted
            playsInline
          ></video>
        

        {/* Content on Top */}
        <div className="relative z-20 flex items-center h-[100vh] justify-center text-center py-8">
          <h1 className="text-2xl sm:text-5xl text-white my-10 sm:my-20 font-medium font-roboto tracking-[0.3em] sm:tracking-[0.7em]">
            OUR SERVICES
          </h1>
        </div>
      </div>

      {/* Furniture Design Service Section */}
      <div className="mb-10 flex justify-center">
        <div className="w-[80%] my-16 lg:my-32 lg:mx-0 flex flex-col lg:flex-row items-center">
          {/* Image center and on top for mobile */}
          <div className="flex justify-center w-full lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={Furniture}
              alt="Furniture Design Service"
              className="w-[90%] md:w-[70%] h-auto object-contain"
            />
          </div>
          {/* Text below image for mobile */}
          <div className="text-left lg:w-1/2 mt-8 lg:mt-0 px-4">
            <h2 className="text-2xl md:text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
              Furniture Design Service
            </h2>
            <p className="text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-8">
              Our comprehensive furniture design service is tailored to bring
              your ideas to life with precision and style. From concept to
              completion, we provide everything you need to create exceptional
              furniture pieces.
            </p>
            <h3 className="text-xl md:text-3xl font-cormorant mb-8">
              Our Services Include:
            </h3>
            <ul className="list-disc list-outside text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-4">
              <li><strong>Mood Board:</strong> Visual inspiration and style direction.</li>
              <li>
                <strong>Renderings & CAD Designs:</strong> High-quality 3D renderings and
                detailed CAD drawings for accuracy.
              </li>
              <li>
                <strong>Assembly Instructions:</strong> Easy-to-follow instructions for
                hassle-free assembly.
              </li>
              <li>
                <strong>Material Selections:</strong> Expert guidance on selecting the best
                materials for your designs.
              </li>
              <li>
                <strong>Lifestyle Shoot Renders:</strong> Realistic renders to showcase your
                furniture in a styled setting.
              </li>
              <li>
                <strong>Catalog & Presentation:</strong> Professional catalogs and
                presentations to highlight your designs.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Interior Design Service Section */}
      <div className="mb-10 flex justify-center">
        <div className="w-[80%]  my-16 lg:my-32 lg:mx-0 flex flex-col lg:flex-row-reverse items-center">
          {/* Image on the right for large screens, centered for mobile */}
          <div className="flex justify-center w-full lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={Interior}
              alt="Interior Design Service"
              className="w-[90%] md:w-[80%] h-auto object-contain"
            />
          </div>
          {/* Text below image for mobile */}
          <div className="text-left lg:w-1/2 mt-8 lg:mt-0 px-4">
            <h2 className="text-2xl md:text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
              Interior Design Service
            </h2>
            <p className="text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-8">
              Transform your space with our tailored interior design
              solutions. We focus on creating environments that are both
              functional and visually striking, designed to suit your unique
              style and needs.
            </p>
            <h3 className="text-xl md:text-3xl font-cormorant mb-8">
              Our Services Include:
            </h3>
            <ul className="list-disc list-outside text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-4">
              <li>
                <strong>Space Planning:</strong> Efficient layouts to maximize
                the use of your space.
              </li>
              <li>
                <strong>Furniture Layout:</strong> Thoughtfully arranged
                furniture for optimal flow and comfort.
              </li>
              <li>
                <strong>Color Scheme:</strong> Personalized color palettes to
                enhance the mood and aesthetic of your home.
              </li>
              <li>
                <strong>Material Selection:</strong> High-quality materials
                that bring texture, durability, and style.
              </li>
              {/* <li>
                <strong>Lighting:</strong> Expert lighting solutions to create
                the perfect ambiance for any room.
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      {/* Home Decor Service Section */}
      <div className="mb-10 flex justify-center">
        <div className="w-[80%] my-16 lg:my-32 lg:mx-0 flex flex-col lg:flex-row items-center">
          {/* Image center for mobile */}
          <div className="flex justify-center w-full lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={Decor}
              alt="Home Decor Service"
              className="w-[90%] md:w-[70%] h-auto object-contain"
            />
          </div>
          {/* Text below image for mobile */}
          <div className="text-left lg:w-1/2 mt-8 lg:mt-0 px-4">
            <h2 className="text-2xl md:text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
              Home Decor Service
            </h2>
            <p className="text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-8">
              Transform your home with our personalized decor services and
              unique designs. We offer a complete range of solutions to
              enhance your space with creativity and style.
            </p>
            <h3 className="text-xl md:text-3xl font-cormorant  mb-8">
              Our Services Include:
            </h3>
            <ul className="list-disc list-outside text-xs md:text-md tracking-widest text-slate-800 font-roboto mb-4">
              <li><strong>Mood Board:</strong> Inspiring concepts tailored to your decor vision.</li>
              <li>
                <strong>Renderings & CAD Designs:</strong> Detailed visuals to bring your ideas
                to life.
              </li>
              <li>
                <strong>Material Exploration:</strong> Expert guidance in choosing the right
                materials for your decor.
              </li>
              <li>
                <strong>Unique Designs for Sale:</strong> Discover exclusive, one-of-a-kind
                pieces crafted to elevate any space.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
