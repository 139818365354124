import React, { useState } from "react";



const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

   // Construct the WhatsApp message text with newlines
  const message = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`;

  // Encode the message for URL
  const encodedMessage = encodeURIComponent(message).replace(/%0A/g, '%0A'); // Replace new lines with %0A

    // Create WhatsApp API URL
    const whatsappUrl = `https://wa.me/918114437116?text=${encodedMessage}`;

    // Open the WhatsApp URL to send the message
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className=" flex flex-col md:flex-row ">
      <div className="contact__left-side w-full flex items-center my-24">
        <div className="contact__text-content text-left w-full mt-8 lg:mt-0">
          <div className="mx-8 sm:mx-24 lg:mx-24">
            <p className="font-roboto text-sm text-slate-700 tracking-[0.75rem] lg:mb-4">
              CONTACT
            </p>
            <h2 className="text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
              Let's stay connected
            </h2>
            <p className="text-xs tracking-widest text-gray-500 font-roboto mb-12">
              Get in touch by leaving your details and the full project scope. I
              would love to hear your ideas on how we can create striking images
              together.
            </p>
            <p className="text-xs tracking-widest font-roboto mb-4">
              <strong>phone: </strong>+918114437116
            </p>
            <p className="text-xs tracking-widest font-roboto mb-4">
              <strong>email: </strong>  sagardzgns@gmail.com
            </p>
            <p className="text-xs tracking-widest font-roboto mb-4">
              <strong>address: </strong> Jodhpur, India
            </p>
          </div>
        </div>
      </div>
      <div className="contact__right-side md:w-full mx-8 md:mx-0  md: text-left my-24">
        <div className=" md:w-[90%]">
          <form onSubmit={handleSubmit}>
            <div className="flex gap-4">
              <div className="w-1/2 mb-4">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="NAME"
                  required
                  className="w-full px-6 py-6 border border-gray-700 bg-[#f6f6f4] font-roboto text-xs text-slate-700 tracking-[0.25rem] placeholder:text-slate-600"
                />
              </div>

              <div className="w-1/2 mb-4">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[0-9]{10}"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="MOBILE NO."
                  required
                  className="w-full px-6 py-6 border border-gray-700 bg-[#f6f6f4] font-roboto text-xs text-slate-700 tracking-[0.25rem] placeholder:text-slate-600"
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="EMAIL"
                required
                className="w-full px-6 py-6 border border-gray-700 bg-[#f6f6f4] font-roboto text-xs text-slate-700 tracking-[0.25rem] placeholder:text-slate-600"
              />
            </div>
            <div className="mb-4">
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                placeholder="MESSAGE"
                required
                className="w-full px-6 py-6 h-[30vh] border border-gray-700 bg-[#f6f6f4] font-roboto text-xs text-slate-700 tracking-[0.25rem] placeholder:text-slate-600"
              ></textarea>
            </div>
            <div className="">
              <button
                type="submit"
                className="flex items-center px-6 py-4 border border-gray-700 bg-[#f6f6f4] font-roboto text-xs text-slate-700 tracking-[0.25rem] placeholder:text-slate-600 hover:border-2"
              >
                <div className="pr-8">SUBMIT</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512"
                  className="h-10 w-10 text-slate-800"
                >
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.8z" />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
