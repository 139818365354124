import React from "react";
import { Link } from "react-router-dom";

const homecontact = () => {
  const contact_holder = require("../../media/home/contact/Home_contact.jpg");
  return (
    <div>
      <div className=" flex flex-col md:flex-row">
        <div className="home-contact__left-side md:w-1/2 mt-16 md:my-24 flex justify-center" >
          <div className="w-2/3 lg:w-[30vw] ml-20 md:ml-auto md:mr-10">
            <img
              src={contact_holder}
              alt="Natelee Cocks"
              className=" object-contain"
            />
          </div>
        </div>
        <div className="home-contact__right-side md:w-1/2 mx-8 md:mx-0  md:text-left my-8 sm:my-24 flex items-center">
          <div className="home-contact__text-content text-left w-full mt-8 lg:mt-0 ">
            <div className="mx-2 sm:mx-24 lg:mx-24">
              <p className="font-roboto text-xs md:text-sm text-slate-700 tracking-[0.75rem] mb-4">
                CONTACT
              </p>
              <h2 className=" text-2xl md:text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
                Let's stay connected
              </h2>
              <p className="text-xs tracking-widest text-gray-500 font-roboto mb-12">
                Get in touch by leaving your details and the full project scope.
                I would love to hear your ideas on how we can create striking
                images together.
              </p>
              <Link to="/contact"
              >
                <div className="flex justify-start w-48 md:w-52 hover:w-56 transition-all duration-300 ease-in-out">
                    <div className="w-full py-[10px]  ">
                      <hr className=" md:border-1 border-black" />
                    </div>
                    <p className="text-[0.7rem] px-4 tracking-[0.4rem] text-gray-800 font-roboto mb-12">CONTACT</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default homecontact;
