import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./home_services.css";

const serviceItems = [
  {
    id: 1,
    title: "Interior",
    description: "Custom spaces designed with creativity and functionality.",
    image: require("../../media/home/cards/Card_Interior.png"),
  },
  {
    id: 2,
    title: "Furniture",
    description: "Bespoke furniture pieces tailored to your unique space.",
    image: require("../../media/home/cards/Card_Furniture.png"),
  },
  {
    id: 3,
    title: "Decor",
    description: "Exclusive home decor items to elevate your living space.",
    image: require("../../media/home/cards/Card_Decor.png"),
  },
  {
    id: 4,
    title: "Planning",
    description: "Optimize your space with efficient layouts and design.",
    image: require("../../media/home/cards/Card_Planning.png"),
  },
  // {
  //   id: 5,
  //   title: "Lighting",
  //   description: "Expert lighting solutions for every room and mood.",
  //   image: require("../../media/home/cards/Card_Lighting.png"),
  // }
];

const Services = () => {
  const containerRef = useRef(null);

  const goNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 240 + 20, // Scroll by the card width plus margin
        behavior: 'smooth', // Smooth scroll
      });
    }
  };

  const goPrevious = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -(240 + 20), // Scroll backward by the card width plus margin
        behavior: 'smooth', // Smooth scroll
      });
    }
  };

  return (
    <div className="services-container bg-[#f6f6f4] py-10 sm:my-40 relative">
      <div className="container mx-auto relative">
        <h2 className="text-2xl sm:text-3xl text-center text-[#776f60] my-10 mt-20 sm:my-20 font-medium font-roboto tracking-[0.3em] sm:tracking-[0.7em]">
          OUR SERVICES
        </h2>
        
        {/* Flex container for the scrollable area and arrows */}
        <div className="relative w-full">
          <button onClick={goPrevious} className="arrow left-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 md:w-12 md:h-12"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </button>

          <div className="cards-wrapper" ref={containerRef}>
            <div className="cards-container">
              {serviceItems.map((service) => (
                <div key={service.id} className="service-card">
                  <img src={service.image} alt={service.title} className="service-image" />
                  <div className="service-content p-4">
                    <h3 className="text-lg md:text-xl font-medium font-roboto text-[#776f60]">
                      {service.title}
                    </h3>
                    <p className="text-xs md:text-sm text-[#aea390] mt-1 font-medium font-roboto">
                      {service.description}
                    </p>
                    <Link to='/services' className="read-more">READ MORE</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button onClick={goNext} className="arrow right-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className=" w-5 h-5 md:w-12 md:h-12"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};


export default Services;
