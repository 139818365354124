
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route, useLocation
} from "react-router-dom";
import Navbar from './components/Navbar';
import Portfolio from './pages/portfolio';
import Shop from './pages/shop';
import About from './pages/about';
import Home from './pages/home';
import Contact from './pages/contact';
import Services from './pages/services';
import Footer from './components/Footer';

function App() {
  // ScrollToTop logic inside App.js
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]); // Triggered whenever the route (pathname) changes

  return null;
};
  return (
    <div className="App bg-[#f6f6f4]">
      <Router>
        <ScrollToTop/>
    <Navbar/>
    <div className="container">
    <Routes>
      <Route exact path="/" element={ <Home/>}/>
      <Route exact path="/about" element={<About/>}/>
      <Route exact path="/portfolio" element={<Portfolio/>}/>
      <Route exact path="/shop" element={<Shop/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="/services" element={<Services/>}/>

    </Routes>
    </div>
    <Footer/>
   </Router>
    </div>
  );
}

export default App;
