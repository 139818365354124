import React from 'react'
import Overview from "../components/home/overview";
import Editor from "../components/home/editor";
import Services from "../components/home/home_services";
import HomeContact from '../components/home/home_contact';
const home = () => {
  return (
    <div>
      <Overview/>
      <Editor/>
      <Services/>
      <HomeContact/>
      
    </div>
  )
}

export default home
