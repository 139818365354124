import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#3d3930] text-stone-400 p-10 z-10">
      {/* Footer Content */}
      <div className="flex justify-center">
        {/* Main Footer Grid */}
        <div className="flex flex-wrap w-full lg:w-5/6">
          {/* Column 1 */}
          <div className="mb-6 w-full sm:w-1/2 lg:w-1/5 ">
            <ul className="space-y-2 text-left">
              <li>
                <Link
                  to="/about"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  ABOUT
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  SERVICES
                </Link>
              </li>
              {/* <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                 Q & A
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  BLOG
                </a>
              </li> */}
              {/* <li>
                <Link
                  to="/shop"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  SHOP
                </Link>
              </li> */}
              <li>
                <Link
                  to="/contact"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 2 */}
          <div className="mb-6  lg:mr-8  sm:w-1/2 lg:w-1/4 ">
            {/* <ul className="space-y-2 text-left">
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  PRIVACY POLICY
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  SHIPPING POLICY
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  RETURN POLICY
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  REFUND POLICY
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  TERMS & CONDITIONS
                </a>
              </li>
            </ul> */}
          </div>

          {/* Column 3 */}
          <div className="mb-6 w-full sm:w-1/2 lg:w-1/4">
            {/* <ul className="space-y-2 text-left">
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  INTERIOR
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  PRODUCT
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  ARCHITECTURE
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  HOTELS & RESORTS
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-xs font-light font-roboto tracking-[0.5em]"
                >
                  TRAVEL
                </a>
              </li>
            </ul> */}
          </div>

          {/* Empty Column for spacing or future use */}
          <div className="mb-6 w-full sm:w-1/2 lg:w-1/5"></div>
        </div>
      </div>

      {/* Search Section */}
      <div className="w-full flex justify-center h-32">
        {/* <div className="flex justify-end items-center lg:w-5/6">
          <div className="w-full lg:w-1/6 lg:mr-24 mb-6 lg:mb-0">
            <form className="flex items-center bg-gray-700 rounded-lg overflow-hidden">
              <input
                type="text"
                placeholder="Search..."
                className="w-full p-2 text-gray-200 bg-transparent outline-none"
              />
              <button type="submit" className="p-2">
                <img src="search-icon.png" alt="Search" className="w-5 h-5" />
              </button>
            </form>
            <hr className="border-t-1 border-gray-600 mt-4" />
          </div>
          </div */}
      </div>

      {/* Footer Bottom Bar */}
      <div className="flex justify-center w-full">
        <div className="flex flex-col lg:flex-row justify-between items-center mt-8 lg:w-5/6 ">
          {/* Copyright */}
          <p className="text-center text-xs font-light font-roboto tracking-[0.5em] lg:text-left mb-4">
            ©2023 SAGAR ALL RIGHTS RESERVED
          </p>
          {/* Social Icons */}
          <div className="flex space-x-4 mb-4 lg:mb-0">
            <a href="/">
              <span class="[&>svg]:h-4 [&>svg]:w-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 320 512"
                  className="facebook-icon"
                >
                  <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                </svg>
              </span>
            </a>
            <a href="https://www.linkedin.com/company/sagar-dgzns/">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512"
                  className="linkedin-icon"
                >
                  <path d="M100.28 448H7.4V149.25h92.88V448zm-46.44-331.3c-29.81 0-54.05-24.24-54.05-54.05 0-29.82 24.24-54.05 54.05-54.05s54.05 24.23 54.05 54.05c0 29.81-24.24 54.05-54.05 54.05zm368.84 331.3h-92.88V302.4c0-34.92-12.48-58.73-43.67-58.73-23.85 0-37.96 16.07-44.21 31.57-2.28 5.52-2.84 13.19-2.84 20.88v152.15h-92.88s1.24-247.87 0-273.36h92.88v38.72c-0.18.27-.42.61-.62.9h.62v-.9c12.32-19.08 34.35-46.36 83.42-46.36 61.19 0 106.82 39.87 106.82 125.13V448z" />
                </svg>
              </span>
            </a>
            <a href="https://www.instagram.com/sagardzgns/">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512"
                  className="instagram-icon"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </span>
            </a>
            <a href="mailto:sagardzgns@gmail.com">
              <span class="[&>svg]:h-5 [&;>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  className="mail-icon"
                >
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
