import React from 'react';
import Sagar from '../media/about/IMG_SAGAR.png';

const About = () => {
  return (
    <div className="about-container my-16 lg:my-32 lg:mx-32 flex flex-col lg:flex-row">
      <div className="about-image lg:flex lg:justify-center mx-8 sm:mx-24 lg:mx-0 lg:w-1/2">
        <img 
          src={Sagar} 
          alt="Sagar" 
          className="w-full h-auto object-contain" 
        />
      </div>
      <div className="about-text lg:flex items-center text-left w-full lg:w-1/2 mt-8 lg:mt-0">
        <div className="mx-8 sm:mx-24 lg:mx-0 lg:ml-24">
          <p className='font-roboto text-sm text-slate-700 tracking-[0.75rem] lg:mb-4'>ABOUT US</p>
          <h2 className="text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">Sagar DZGNS</h2>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-2">
            Welcome to SAGAR DZGNS, where art meets home! Founded in November 2024, we are a passionate design firm dedicated to transforming living spaces into unique, personalized havens. With over 15 years of experience in the furniture and home decor design industry, we bring creativity, innovation, and craftsmanship to every project.
          </p>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-2">
            Before stepping into the world of furniture and home decor design in 2009, our founder, with a rich background in the arts, began their career as a skilled artist working across various forms of painting. This artistic foundation drives our attention to detail and passion for creating spaces that inspire.
          </p>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-2">
            <strong>What We Do</strong>
          </p>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-2">
            At SAGAR DZGNS, we offer a comprehensive range of services tailored to your needs. Our design services include:
          </p>
          <ul className='list-disc text-xs tracking-widest text-gray-500 font-roboto mb-2'>
            <li><strong>Interior Design Solutions:</strong> From conceptualization to execution, we offer custom designs that suit your lifestyle and aesthetic.</li>
            <li><strong>Furniture Design Solutions:</strong> We create bespoke furniture pieces that blend style with practicality, made to complement your home perfectly.</li>
            <li><strong>Designer Home Decor Products:</strong> Our handpicked collection of exclusive decor items is designed to elevate the charm and warmth of your living space.</li>
          </ul>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-2">
            <strong>Why Choose Us?</strong>
          </p>
          <ul className='list-disc text-xs tracking-widest text-gray-500 font-roboto mb-2'>
            <li><strong>Experience & Expertise:</strong> With a solid background in art and design, we have the creative vision and technical knowledge to bring any space to life.</li>
            <li><strong>Personalized Approach:</strong> We believe that design is personal. That’s why we collaborate closely with our clients to ensure their vision is realized, down to the finest detail.</li>
            <li><strong>High-Quality Craftsmanship:</strong> We take pride in using the finest materials and craftsmanship to create pieces that not only look stunning but are built to last.</li>
            <li><strong>Innovative Design Solutions:</strong> We combine functionality with cutting-edge design trends, delivering spaces and products that are both timeless and contemporary.</li>
          </ul>
          <p className="text-xs tracking-widest text-gray-500 font-roboto mb-4">
            At SAGAR DZGNS, we believe that your home should be a reflection of your individuality. That’s why we not only offer design services but also a curated collection of designer home decor products, each piece crafted with care to enhance the beauty of your space. From concept to completion, we’re here to bring your dream home to life.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
