import React from 'react';
import { Link } from 'react-router-dom';

const Editor = () => {
    const image3 = require('../../media/services/Service_Decor.png');
    
    return (
        <div>
            <div className="mb-10">
                <div className="w-full my-16 lg:my-32 lg:mx-0 flex flex-col lg:flex-row justify-end items-center">
                    {/* Text below image for mobile */}
                    <div className='flex justify-center'>
                        <div className="text-left w-1/2 mt-8 lg:mt-0 px-4">
                            <p className="font-roboto text-xs md:text-sm text-slate-700 tracking-[0.75rem] mb-4">
                                WELCOME
                            </p>
                            <h2 className="text-2xl md:text-5xl font-cormorant text-slate-800 mb-4 lg:mb-8">
                                I'm Sagar
                            </h2>
                            <p className="text-xs tracking-widest text-gray-500 font-roboto mb-12">
                                At SAGAR DZGNS, we specialize in transforming your space with tailored interior design solutions. 
                                Our focus is on creating environments that are both functional and visually striking, designed to suit your unique style and needs.
                            </p>
                            <Link to="/about">
                                <div className="flex justify-end w-48 md:w-52 hover:w-56 transition-all duration-300 ease-in-out">
                                    <div className="w-full py-[10px]">
                                        <hr className="md:border-1 border-black" />
                                    </div>
                                    <p className="text-[0.7rem] px-4 tracking-[0.4rem] text-gray-800 font-roboto mb-12">ABOUT</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* Image on the right for large screens, centered for mobile */}
                    <div className="w-1/2 flex justify-end mb-8 lg:mb-0">
                        <img
                            src={image3}
                            alt="Interior Design Service"
                            className="w-[90%] md:w-full h-auto object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Editor;
