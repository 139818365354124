import React from 'react'
import Loading from '../components/loading/loading';
const shop = () => {
  return (
    <div>
      <Loading/>
    </div>
  )
}

export default shop
