import React from 'react'

const images = [
  require('../media/portfolio/decor_&_accents/IMG_3227.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3228.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3229.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3232.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3234.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3235.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3258.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3259.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3262.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3264.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3269.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3272.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3278.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3275.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3273.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3286.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3288.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3288.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3291.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3293.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3294.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/IMG_3295.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (1) 1.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (1) 2.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (1) front.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (3) 1.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (3) 2.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Plates (3) front.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Snacks Bowl (2) 1.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Snacks Bowl (2) 2.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Snacks Bowl (2) 3.jpeg'), // replace these URLs with your images
  require('../media/portfolio/decor_&_accents/Snacks Bowl (2) front.jpeg'), // replace these URLs with your images
  
 
];

const divideArray = (arr, num) => {
  const result = Array.from({ length: num }, () => []);
  arr.forEach((item, index) => {
    result[index % num].push(item);
  });
  return result;
};

function portfolio() {
  const columns = divideArray(images, 3); // Divide images into 3 columns

  return (
    <div className="bg-[#f6f6f4]">
      <div className="portfolio-head-container border-t-2">
      <div className="text-left mx-6 lg:mx-28 py-8">
        <div className="heading-portfolio text-4xl mx-2 font-cormorant">
          Hi There,
        </div>
        <div className="description-portfolio text-xs text-[#776f60] font-roboto py-4 mx-2 tracking-widest">
        Space and light and order. These are considered by designers when creating an interior space. I embrace each space in its intended light and aim to convey a feeling of order in my images.
        </div>
      </div>
      </div>
      <div className="flex justify-between md:mx-4 lg:mx-28">
  {columns.map((columnImages, columnIndex) => (
    <div key={columnIndex} className="flex flex-col w-1/2 m-1 md:m-2">
      {columnImages.map((image, index) => (
        <img key={index} src={image} alt={`Gallery ${index}`} className="w-full h-auto my-1 md:my-2" />
      ))}
    </div>
  ))}
</div>
    </div>
  );
}


export default portfolio
