import React, { useState, useEffect } from 'react';
import './overview.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const images = [
  { 
    src: require('../../media/home/bg/Interior_Design.png'), 
    text: 'SAGAR DZGNS offers custom interior design solutions tailored to your lifestyle and aesthetics. We blend creativity with functionality to craft spaces that inspire.', 
    header: 'INTERIOR', 
    title: 'Interior Design' 
  },
  { 
    src: require('../../media/home/bg/Furniture_Design.png'), 
    text: 'From initial sketches to the final product, we design and build furniture that complements your space perfectly, combining style and comfort.', 
    header: 'FURNITURE', 
    title: 'Furniture Design' 
  },
  { 
    src: require('../../media/home/bg/Decor_Design.png'), 
    text: 'Our curated collection of home decor products is designed to enhance the warmth and beauty of your living space. Discover one-of-a-kind pieces that make a statement.', 
    header: 'DECOR', 
    title: 'Home Decor' 
  }
];


const Overview = () => {
  const [index, setIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeOut(false);
      }, 10); // Match this with the CSS transition duration
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className=".overview__image-text-section bg-[#f6f6f4] flex flex-row h-[70vh]  sm:h-[100vh] md:h-[82vh]">
      {/* Left Column for Text */}
      <div className="overview__text-container border-t-2 border-gray-400 md:mt-[3%]">
        <div className={`overview__text-content ${fadeOut ? 'overview__fade-out' : 'overview__fade-in'} text-left  overflow-visible ml-8 sm:ml-[20vh]`}>
          <div className=" md:text-[#3E3930] text-xs sm:text-[0.7rem] font-medium font-roboto tracking-[0.7em] mb-8">
            {images[index].header}
          </div>
          <div className=" md:text-[#3E3930] text-4xl md:text-8xl text-nowrap italic font-cormorant mb-8">
            {images[index].title}
          </div>
          <div className="md:text-[#3E3930]  text-lg md:text-2xl italic font-medium font-cormorant mb-8">
            {images[index].text}
          </div>
          <Link to='/services' className='text-[#3E3930]  px-3 md:px-10 py-4 border  border-[#3E3930] hover:bg-[#3E3930] hover:text-white text-[0.5rem] sm:text-[0.7rem] font-medium font-roboto tracking-[0.7em] mb-8 duration-300'>READ MORE</Link>
        </div>
      </div>

      {/* Right Column for Images */}
      <div className="overview__image-container flex justify-end md:justify-center">
        {images.map((image, i) => (
          <img
            key={i}
            src={image.src}
            alt={`service ${i + 1}`}
            className={`overview__image-content ${i === index ? 'fade-in' : 'fade-out'} h-[70vh] md:max-w-full md:h-[100vh]`}
          />
        ))}
      </div>
    </div>
  );
};

export default Overview;
