import React, { useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import Logo from '../media/navbar/logo/logo(PNG).png';

function Navbar() {
  // State to toggle mobile menu
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className=" shadow-sm">
      <div className="w-full pr-8 flex justify-between items-center">
        {/* Left side links */}
        <div className="ml-8 text-slate-700 text-[0.8rem] font-medium font-roboto tracking-[1em]">
        <Link to="/"><img src={Logo} className="h-24" alt="Instagram" /></Link>
        </div>

        {/* Right side links */}
        <div className="hidden lg:flex space-x-8 py-10">
          {/* <Link to="/" className="nav-link hover:text-yellow-900 text-slate-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">OVERVIEW</Link> */}
          <Link to="/about" className="nav-link hover:text-yellow-900 text-slate-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">ABOUT</Link>
          {/* <Link to="/portfolio" className="nav-link hover:text-yellow-900 text-slate-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">PORTFOLIO</Link> */}
          <Link to="/services" className="nav-link text-slate-700 hover:text-yellow-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">SERVICES</Link>
          {/* <Link to="/shop" className="nav-link hover:text-yellow-900 text-slate-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">SHOP</Link> */}
          <Link to="/contact" className="nav-link hover:text-yellow-900 text-slate-700 text-[0.7rem] font-medium font-roboto tracking-[0.7em]">CONTACT</Link>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="relative w-8 h-8 flex justify-center items-center focus:outline-none">
            {/* Hamburger to Cross Icon Animation */}
            <svg
              className={`h-6 w-6 transition-transform duration-300 ease-in-out ${isOpen ? 'transform rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <div className="md:hidden pb-4 space-y-4 text-center">
          {/* <Link to="/" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">OVERVIEW</Link> */}
          <Link to="/about" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">ABOUT</Link>
          {/* <Link to="/portfolio" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">PORTFOLIO</Link> */}
          <Link to="/services" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">SERVICES</Link>
          {/* <Link to="/shop" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">SHOP</Link> */}
          <Link to="/contact" className="block text-slate-700 my-2 hover:text-yellow-900 text-[0.8rem] font-medium font-roboto tracking-[0.7em]">CONTACT</Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
